import {
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { borderRadius, display, styled, textAlign } from "@mui/system";
import Pagination from "@mui/material/Pagination";

import Searchbar from "../../../common/Searchbar";
import Phonecall from "../../../assets/Phonecall.png";
import Modal from "@mui/material/Modal";
import { toast } from "react-toastify";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

import Skeleton from "@mui/material/Skeleton";

const BXICommissionList = () => {
  const StyledPagination = styled(Pagination)({
    "& .MuiPaginationItem-root": {
      color: "#1976d2",
    },
    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .css-1v2lvtn-MuiPaginationItem-root": {
      marginRight: "16px",
      border: "none !important",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [initialPaginationCount, setInitialPaginationCount] = useState(
    currentPage || 1
  );
  const [totalDues, setTotalDues] = useState();
  const [totalDuesPageWise, setTotalDuesPageWise] = useState();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [storeUserDetails, setStoreUserDetails] = useState({});

  const [searchData, setSearchData] = useState("");
  const [fromdateFilter, setFromDateFilter] = useState("");
  const [todateFilter, setToDateFilter] = useState("");

  const [openDateModal, setOpenDateModal] = useState(false);
  const handleOpenDateModal = () => setOpenDateModal(true);
  const handleCloseDateModal = () => setOpenDateModal(false);
  const [storeDate, setStoreDate] = useState({
    fromDate: null,
    toDate: null,
  });

  const handleChildData = (dataFromChild) => {
    setSearchData(dataFromChild);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleFromDateChange = (date) => {
    setFromDateFilter(date);
  };

  const handleToDateChange = (date) => {
    setToDateFilter(date);
  };
  const fetchAllMemberships = async ({ fromDate, toDate }) => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams({
        page: currentPage,
        search: searchData,
        "storeDate[fromDate]": fromDate,
        "storeDate[toDate]": toDate,
      }).toString();

      const response = await axios.get(
        `commission/get_all_bxi_commision_for_admin?${queryParams}`
      );

      setTotalDues(response?.data?.totalDues);
      setTotalDuesPageWise(response?.data?.totalDuesPageWise);
      setData(response.data?.Data);
      setInitialPaginationCount(response.data?.totalPages);
      setLoading(false);
    } catch (error) {
      console.log("errorsdhfgivusdf", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllMemberships({
      fromDate: storeDate?.fromDate,
      toDate: storeDate?.toDate,
    });
  }, [initialPaginationCount, currentPage, searchData, storeDate]);

  const generatePaymentLink = async (OrderId) => {
    try {
      await axios.post(`commission/generate_payment_link`, {
        OrderId: OrderId,
      });
    } catch (error) {
      toast.error(error.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
      });
    }
  };

  console.log("storeDate", storeDate);

  return (
    <Paper>
      <Searchbar onSendData={handleChildData} />
      <Paper
        elevation={0}
        sx={{
          width: "98%",
          display: "flex",
          justifyContent: "space-between",
          mx: "auto",
        }}
      >
        <Box
          sx={{
            display: "grid",
            justifyContent: "space-between",
            padding: "10px",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              padding: "10px",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
              Total Dues:{" "}
            </Typography>
            <Typography sx={{ fontWeight: 600, fontSize: "20px" }}>
              {totalDues?.toFixed(2)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              padding: "10px",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
              Total Dues on this Page:{" "}
            </Typography>

            <Typography sx={{ fontWeight: 600, fontSize: "20px" }}>
              {totalDuesPageWise?.toFixed(2)}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "300px",
              mt: "10px",
            }}
          >
            <Typography>Filter by Date: </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenDateModal}
              sx={{
                maxWidth: "200px",
              }}
            >
              Select Date Range
            </Button>
          </Box>
          <Modal
            open={openDateModal}
            onClose={handleCloseDateModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalstyle}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "grid",
                    justifyContent: "space-between",
                    padding: "16px",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography sx={{ fontSize: "18px" }}>
                    Select From date
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar
                      onChange={(date) =>
                        handleFromDateChange(new Date(date?.$d).toISOString())
                      }
                    />
                  </LocalizationProvider>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    justifyContent: "space-between",
                    padding: "16px",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography sx={{ fontSize: "18px" }}>
                    Select To date
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar
                      onChange={(date) =>
                        handleToDateChange(new Date(date?.$d).toISOString())
                      }
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
              <Button
                variant="contained"
                sx={{
                  width: "80%",
                  mx: "auto",
                }}
                onClick={() => {
                  setStoreDate({
                    fromDate: fromdateFilter,
                    toDate: todateFilter,
                  });
                  handleCloseDateModal();
                }}
              >
                Apply
              </Button>
            </Box>
          </Modal>
        </Box>
        <Box
          sx={{
            minWidth: "400px",
            textAlign: "right",
          }}
        >
          {/* <Button
            variant="contained"
            sx={{
              textDecoration: "none",
              textTransform: "none",
            }}
          >
            Download
          </Button> */}
        </Box>
      </Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Sr. No</TableCell>
              <TableCell>Company Name</TableCell>
              <TableCell>Order ID</TableCell>
              <TableCell>Commission & TDS Amount (₹)</TableCell>
              <TableCell>Overdue By (days)</TableCell>
              <TableCell>Category</TableCell>
              <TableCell rowSpan={1} colSpan={1}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={7}>
                  <Skeleton
                    animation="wave"
                    sx={{
                      width: "100%",
                      height: 70,
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={7}>
                  <Skeleton
                    animation="wave"
                    sx={{
                      width: "100%",
                      height: 70,
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={7}>
                  <Skeleton
                    animation="wave"
                    sx={{
                      width: "100%",
                      height: 70,
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={7}>
                  <Skeleton
                    animation="wave"
                    sx={{
                      width: "100%",
                      height: 70,
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={7}>
                  <Skeleton
                    animation="wave"
                    sx={{
                      width: "100%",
                      height: 70,
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={7}>
                  <Skeleton
                    animation="wave"
                    sx={{
                      width: "100%",
                      height: 50,
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {data?.map((response, index) => {
                const daysDiff = Math.floor(
                  (new Date() - new Date(response?.createdAt)) /
                    (1000 * 60 * 60 * 24)
                );
                const continuousIdx = (currentPage - 1) * 10 + index + 1;
                return (
                  <TableRow key={index}>
                    <TableCell>{continuousIdx}</TableCell>
                    <TableCell>
                      {response?.SellerDetails?.SellerCompanyName}
                    </TableCell>
                    <TableCell>{response?.OrderId}</TableCell>
                    <TableCell>
                      {(
                        Number(response?.Commission?.CommissionGST) +
                        Number(response?.Commission?.CommissionPrice) +
                        Number(response?.orders_result?.INRDetails?.TDS)
                      ).toFixed(2)}
                    </TableCell>
                    <TableCell>
                      {response?.JuspayResponse
                        ? response?.JuspayResponse?.status === "CHARGED"
                          ? "Paid"
                          : daysDiff + " Days"
                        : daysDiff + " Days"}
                    </TableCell>
                    <TableCell>
                      {response?.ProductData?.at(0)?.ProductTypeName}
                    </TableCell>
                    <TableCell rowSpan={1} colSpan={1}>
                      {response?.JuspayResponse ? (
                        response?.JuspayResponse?.status !== "CHARGED" ? (
                          <Box
                            sx={{
                              width: "27px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleOpen();
                              setStoreUserDetails(response?.orders_result);
                            }}
                          >
                            <img
                              src={Phonecall}
                              width={"100%"}
                              height={"auto"}
                            />
                          </Box>
                        ) : (
                          "Paid"
                        )
                      ) : (
                        <Box
                          sx={{
                            width: "27px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleOpen();
                            setStoreUserDetails(response?.orders_result);
                          }}
                        >
                          <img src={Phonecall} width={"100%"} height={"auto"} />
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Order ID:</TableCell>
                <TableCell>{storeUserDetails?.OrderId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Email:</TableCell>
                <TableCell>
                  {
                    storeUserDetails?.TaxInvoice?.SellerDetails
                      ?.SellerCompanyEmail
                  }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Phone:</TableCell>
                <TableCell>
                  {
                    storeUserDetails?.TaxInvoice?.SellerDetails
                      ?.SellerCompanyContact
                  }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Generate Payment Link:</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() =>
                      generatePaymentLink(storeUserDetails?.OrderId)
                    }
                  >
                    Generate Payment Link
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Modal>
      <Box sx={PaginationBox} mt={2}>
        <Stack>
          <StyledPagination
            size="medium"
            count={initialPaginationCount}
            color="primary"
            showFirstButton
            showLastButton
            page={currentPage}
            onChange={handlePageChange}
            strokeWidth={currentPage}
          />
        </Stack>
      </Box>
    </Paper>
  );
};

export default BXICommissionList;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  borderRadius: 10,
  p: 4,
};

const PaginationBox = {
  display: "flex",
  justifyContent: "center",
  width: {
    xl: "100%",
    lg: "100%",
    md: "100%",
    sm: "100%",
    xs: "100%",
  },
  mx: "auto",
};

const modalstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "800px",
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  borderRadius: 10,
  p: 4,
  alignItems: "center",
  textAlign: "center",
};
